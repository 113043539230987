import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Formulario from "../components/widgets/formulario.js"

import bgForm from "../images/Formulario/Imagen.jpg"

import "../styles/banner.css"
import "../styles/contact-btn.css"
import "../styles/section.css"

const ContactoPage = () => (
  <Layout>
    <SEO title="Contacto"
    description = "Ponte en contacto con nosotros."
    url = "https://www.veleztax.com/contacto"
    image = {bgForm} 
     />
    <div className="white_space_contacto" />
    <Formulario />
  </Layout>
)

export default ContactoPage
